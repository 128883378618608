/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Promaton HTTP API
 * This API allows you to upload STL and 3D CBCT images for segmentation.

## Tasks
Because inferences take longer than some proxy servers allow open
connection for, we accept all uploads as a `task` which you
can monitor until it reaches completion.

Monitoring can be done via polling.

## Authentication

Authentication is done by setting your api key in the `Authorization`
header, prefixed with `Bearer`, like so: `Bearer <yourtoken>`.

Depending on your API token, you can have access to either OSS, CBCT,
ACD or all.

## Uploading your first STL
You can upload your first STL using the following curl command:

```sh
curl -X POST "https://api.promaton.com/oss/lower" \
    -H "Content-Type: model/stl" \
    --data-binary "@path_to_stl.lower.stl" \
    --header "Authorization: Bearer <your token>" \
    --http1.1 --verbose \
```

After that, you can get the status of your task using
the id of the task returned to you, in the following
curl command:

```sh
curl "https://api.promaton.com/oss/{your task id}" \
    --header "Authorization: Bearer <your token>" \
    --http1.1\
```

Until it reaches the completed state.

You can then use either the `stl` output, or `polyline` output endpoints
to get the result. If an error occurs, the state will switch to `errored`.

## Response times

We shut down our servers whenever possible to reduce cost. You therefore
may experience a "cold start" when first calling our API. This takes
at most five minutes. After that, the response times should be on par.

## Task pagination
All tasks offer a pagination endpoint. Because tasks can be added while
you are paginating, we work with a pointer-based approach using a task id.

If you want to start paginating from the most recent task to older tasks,
you can call (for example) `/cbct?sort_order=desc&limit=10`. Then, for the
next 10 items, you take the id last item of the previous result, and send
that along as the `from_id` like this:
`/cbct?sort_order=desc&limit=15&from_id=ckbulxexk0016xapiz8h3g2pn`.

## Known issues
- Currently, there is an issue with HTTP-v2 and curl. Please use
  the `--http1.1` command to circumvent it.

## Changelog
The changelog has moved and is available <a href="/docs/changelog">here</a>

## Patents
The following products are protected by patents in the U.S. and elsewhere, all
or some of which are third party intellectual property rights. This website is
provided to satisfy the virtual patent marking provisions of various jurisdictions
including the virtual patent marking provisions of the America Invents Act. The
following list of products may not be all inclusive, and other products not
listed here may be protected by one or more patents.

`US Patent Number 11,379,975, US Patent Number 11,568,533` - for Segmentation of 3D anatomical structures

`US Patent Number 11,455,774` - for Root Shape Prediction

`US Patent Number 12,229,993` - Alignment of 3D tooth surfaces using deep learning
 * OpenAPI spec version: 10.17.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AlignmentListTasks,
  AlignmentResultType,
  AlignmentTask,
  BadRequestResponse,
  CreateAlignmentTaskParams,
  ErrorResponse,
  ForbiddenResponse,
  GetAlignmentTasksParams,
  GoneResponse,
  NotFoundResponse,
  UnauthorizedResponse
} from '../model'




/**
 * @summary Get the results for an Alignment API call
 */
export const getAlignmentTask = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AlignmentTask>> => {
    
    return axios.get(
      `/alignment/${taskId}`,options
    );
  }


export const getGetAlignmentTaskQueryKey = (taskId: string,) => {
    return [`/alignment/${taskId}`] as const;
    }

    
export const getGetAlignmentTaskQueryOptions = <TData = Awaited<ReturnType<typeof getAlignmentTask>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAlignmentTask>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAlignmentTaskQueryKey(taskId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlignmentTask>>> = ({ signal }) => getAlignmentTask(taskId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAlignmentTask>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAlignmentTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getAlignmentTask>>>
export type GetAlignmentTaskQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Get the results for an Alignment API call
 */
export const useGetAlignmentTask = <TData = Awaited<ReturnType<typeof getAlignmentTask>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAlignmentTask>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAlignmentTaskQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Deletes a task
 */
export const deleteAlignment = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/alignment/${taskId}`,options
    );
  }



export const getDeleteAlignmentMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAlignment>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAlignment>>, TError,{taskId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAlignment>>, {taskId: string}> = (props) => {
          const {taskId} = props ?? {};

          return  deleteAlignment(taskId,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteAlignmentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAlignment>>>
    
    export type DeleteAlignmentMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

    /**
 * @summary Deletes a task
 */
export const useDeleteAlignment = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAlignment>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getDeleteAlignmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Creates a new Alignment API request, the user provides at least one
CBCT task, and one OSS task. Optionally a time is provided for how
long the results are stored for the user (to a 30 day maximum).

 * @summary Create a new request for the Alignment API
 */
export const createAlignmentTask = (
    params: CreateAlignmentTaskParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AlignmentTask>> => {
    
    return axios.post(
      `/alignment`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateAlignmentTaskMutationOptions = <TError = AxiosError<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAlignmentTask>>, TError,{params: CreateAlignmentTaskParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createAlignmentTask>>, TError,{params: CreateAlignmentTaskParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAlignmentTask>>, {params: CreateAlignmentTaskParams}> = (props) => {
          const {params} = props ?? {};

          return  createAlignmentTask(params,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateAlignmentTaskMutationResult = NonNullable<Awaited<ReturnType<typeof createAlignmentTask>>>
    
    export type CreateAlignmentTaskMutationError = AxiosError<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse>

    /**
 * @summary Create a new request for the Alignment API
 */
export const useCreateAlignmentTask = <TError = AxiosError<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAlignmentTask>>, TError,{params: CreateAlignmentTaskParams}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getCreateAlignmentTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get all Alignment tasks for the current authenticated user
 */
export const getAlignmentTasks = (
    params?: GetAlignmentTasksParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AlignmentListTasks>> => {
    
    return axios.get(
      `/alignment`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetAlignmentTasksQueryKey = (params?: GetAlignmentTasksParams,) => {
    return [`/alignment`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAlignmentTasksQueryOptions = <TData = Awaited<ReturnType<typeof getAlignmentTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(params?: GetAlignmentTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAlignmentTasks>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAlignmentTasksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlignmentTasks>>> = ({ signal }) => getAlignmentTasks(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAlignmentTasks>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAlignmentTasksQueryResult = NonNullable<Awaited<ReturnType<typeof getAlignmentTasks>>>
export type GetAlignmentTasksQueryError = AxiosError<ErrorResponse | UnauthorizedResponse>

/**
 * @summary Get all Alignment tasks for the current authenticated user
 */
export const useGetAlignmentTasks = <TData = Awaited<ReturnType<typeof getAlignmentTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(
 params?: GetAlignmentTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAlignmentTasks>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAlignmentTasksQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets an individual structure in the specified format
 */
export const getAlignmentStructure = (
    taskId: string,
    resultType: AlignmentResultType,
    fileType: 'stl' | 'ply',
    structureId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    
    return axios.get(
      `/alignment/${taskId}/${resultType}/${fileType}/${structureId}`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetAlignmentStructureQueryKey = (taskId: string,
    resultType: AlignmentResultType,
    fileType: 'stl' | 'ply',
    structureId: string,) => {
    return [`/alignment/${taskId}/${resultType}/${fileType}/${structureId}`] as const;
    }

    
export const getGetAlignmentStructureQueryOptions = <TData = Awaited<ReturnType<typeof getAlignmentStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    resultType: AlignmentResultType,
    fileType: 'stl' | 'ply',
    structureId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAlignmentStructure>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAlignmentStructureQueryKey(taskId,resultType,fileType,structureId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlignmentStructure>>> = ({ signal }) => getAlignmentStructure(taskId,resultType,fileType,structureId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId && resultType && fileType && structureId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAlignmentStructure>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAlignmentStructureQueryResult = NonNullable<Awaited<ReturnType<typeof getAlignmentStructure>>>
export type GetAlignmentStructureQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets an individual structure in the specified format
 */
export const useGetAlignmentStructure = <TData = Awaited<ReturnType<typeof getAlignmentStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    resultType: AlignmentResultType,
    fileType: 'stl' | 'ply',
    structureId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAlignmentStructure>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAlignmentStructureQueryOptions(taskId,resultType,fileType,structureId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



