/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Promaton HTTP API
 * This API allows you to upload STL and 3D CBCT images for segmentation.

## Tasks
Because inferences take longer than some proxy servers allow open
connection for, we accept all uploads as a `task` which you
can monitor until it reaches completion.

Monitoring can be done via polling.

## Authentication

Authentication is done by setting your api key in the `Authorization`
header, prefixed with `Bearer`, like so: `Bearer <yourtoken>`.

Depending on your API token, you can have access to either OSS, CBCT,
ACD or all.

## Uploading your first STL
You can upload your first STL using the following curl command:

```sh
curl -X POST "https://api.promaton.com/oss/lower" \
    -H "Content-Type: model/stl" \
    --data-binary "@path_to_stl.lower.stl" \
    --header "Authorization: Bearer <your token>" \
    --http1.1 --verbose \
```

After that, you can get the status of your task using
the id of the task returned to you, in the following
curl command:

```sh
curl "https://api.promaton.com/oss/{your task id}" \
    --header "Authorization: Bearer <your token>" \
    --http1.1\
```

Until it reaches the completed state.

You can then use either the `stl` output, or `polyline` output endpoints
to get the result. If an error occurs, the state will switch to `errored`.

## Response times

We shut down our servers whenever possible to reduce cost. You therefore
may experience a "cold start" when first calling our API. This takes
at most five minutes. After that, the response times should be on par.

## Task pagination
All tasks offer a pagination endpoint. Because tasks can be added while
you are paginating, we work with a pointer-based approach using a task id.

If you want to start paginating from the most recent task to older tasks,
you can call (for example) `/cbct?sort_order=desc&limit=10`. Then, for the
next 10 items, you take the id last item of the previous result, and send
that along as the `from_id` like this:
`/cbct?sort_order=desc&limit=15&from_id=ckbulxexk0016xapiz8h3g2pn`.

## Known issues
- Currently, there is an issue with HTTP-v2 and curl. Please use
  the `--http1.1` command to circumvent it.

## Changelog
The changelog has moved and is available <a href="/docs/changelog">here</a>

## Patents
The following products are protected by patents in the U.S. and elsewhere, all
or some of which are third party intellectual property rights. This website is
provided to satisfy the virtual patent marking provisions of various jurisdictions
including the virtual patent marking provisions of the America Invents Act. The
following list of products may not be all inclusive, and other products not
listed here may be protected by one or more patents.

`US Patent Number 11,379,975, US Patent Number 11,568,533` - for Segmentation of 3D anatomical structures

`US Patent Number 11,455,774` - for Root Shape Prediction

`US Patent Number 12,229,993` - Alignment of 3D tooth surfaces using deep learning
 * OpenAPI spec version: 10.17.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  CreateTscBodyTwo,
  CreateTscCustomBodyTwo,
  CreateTscCustomParams,
  CreateTscParams,
  ErrorResponse,
  ForbiddenResponse,
  GetTscTasksParams,
  GoneResponse,
  Landmarks,
  NotFoundResponse,
  PolyLine,
  TscListTasks,
  TscTask,
  UnauthorizedResponse
} from '../model'
import type {
  Pose
} from '../model/docs-common-pose'




/**
 * @summary Gets all tasks (TSC) for the current authenticated user
 */
export const getTscTasks = (
    params?: GetTscTasksParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TscListTasks>> => {
    
    return axios.get(
      `/tsc`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetTscTasksQueryKey = (params?: GetTscTasksParams,) => {
    return [`/tsc`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTscTasksQueryOptions = <TData = Awaited<ReturnType<typeof getTscTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(params?: GetTscTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscTasks>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTscTasksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTscTasks>>> = ({ signal }) => getTscTasks(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTscTasks>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTscTasksQueryResult = NonNullable<Awaited<ReturnType<typeof getTscTasks>>>
export type GetTscTasksQueryError = AxiosError<ErrorResponse | UnauthorizedResponse>

/**
 * @summary Gets all tasks (TSC) for the current authenticated user
 */
export const useGetTscTasks = <TData = Awaited<ReturnType<typeof getTscTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(
 params?: GetTscTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscTasks>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTscTasksQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Starts inference on any optical scan segmentation
in the STL format with tooth shape completion
 * @summary Run optical scan segmentation with tooth shape completion
 */
export const createTsc = (
    jawType: 'upper' | 'lower',
    createTscBody: Blob | CreateTscBodyTwo,
    params?: CreateTscParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TscTask>> => {
    
    return axios.post(
      `/tsc/${jawType}`,
      createTscBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateTscMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTsc>>, TError,{jawType: 'upper' | 'lower';data: Blob | CreateTscBodyTwo;params?: CreateTscParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createTsc>>, TError,{jawType: 'upper' | 'lower';data: Blob | CreateTscBodyTwo;params?: CreateTscParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTsc>>, {jawType: 'upper' | 'lower';data: Blob | CreateTscBodyTwo;params?: CreateTscParams}> = (props) => {
          const {jawType,data,params} = props ?? {};

          return  createTsc(jawType,data,params,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateTscMutationResult = NonNullable<Awaited<ReturnType<typeof createTsc>>>
    export type CreateTscMutationBody = Blob | CreateTscBodyTwo
    export type CreateTscMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse>

    /**
 * @summary Run optical scan segmentation with tooth shape completion
 */
export const useCreateTsc = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTsc>>, TError,{jawType: 'upper' | 'lower';data: Blob | CreateTscBodyTwo;params?: CreateTscParams}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getCreateTscMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets the task object for a TSC inference
 */
export const getTsc = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TscTask>> => {
    
    return axios.get(
      `/tsc/${taskId}`,options
    );
  }


export const getGetTscQueryKey = (taskId: string,) => {
    return [`/tsc/${taskId}`] as const;
    }

    
export const getGetTscQueryOptions = <TData = Awaited<ReturnType<typeof getTsc>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTsc>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTscQueryKey(taskId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTsc>>> = ({ signal }) => getTsc(taskId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTsc>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTscQueryResult = NonNullable<Awaited<ReturnType<typeof getTsc>>>
export type GetTscQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the task object for a TSC inference
 */
export const useGetTsc = <TData = Awaited<ReturnType<typeof getTsc>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTsc>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTscQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Deletes a task
 */
export const deleteTsc = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/tsc/${taskId}`,options
    );
  }



export const getDeleteTscMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTsc>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTsc>>, TError,{taskId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTsc>>, {taskId: string}> = (props) => {
          const {taskId} = props ?? {};

          return  deleteTsc(taskId,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteTscMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTsc>>>
    
    export type DeleteTscMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

    /**
 * @summary Deletes a task
 */
export const useDeleteTsc = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTsc>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getDeleteTscMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Runs tooth shape completion on a zip file containing
STL files of an optical scan and segmented teeth with
fdi notation filenames.
 * @summary Run tooth shape completion on custom data source
 */
export const createTscCustom = (
    jawType: 'upper' | 'lower',
    createTscCustomBody: Blob | CreateTscCustomBodyTwo,
    params?: CreateTscCustomParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TscTask>> => {
    
    return axios.post(
      `/tsc/${jawType}/custom-segmentation`,
      createTscCustomBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateTscCustomMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTscCustom>>, TError,{jawType: 'upper' | 'lower';data: Blob | CreateTscCustomBodyTwo;params?: CreateTscCustomParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createTscCustom>>, TError,{jawType: 'upper' | 'lower';data: Blob | CreateTscCustomBodyTwo;params?: CreateTscCustomParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTscCustom>>, {jawType: 'upper' | 'lower';data: Blob | CreateTscCustomBodyTwo;params?: CreateTscCustomParams}> = (props) => {
          const {jawType,data,params} = props ?? {};

          return  createTscCustom(jawType,data,params,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateTscCustomMutationResult = NonNullable<Awaited<ReturnType<typeof createTscCustom>>>
    export type CreateTscCustomMutationBody = Blob | CreateTscCustomBodyTwo
    export type CreateTscCustomMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse>

    /**
 * @summary Run tooth shape completion on custom data source
 */
export const useCreateTscCustom = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTscCustom>>, TError,{jawType: 'upper' | 'lower';data: Blob | CreateTscCustomBodyTwo;params?: CreateTscCustomParams}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getCreateTscCustomMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets the polyline result of a TSC inference
 */
export const getTscPolyLine = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PolyLine>> => {
    
    return axios.get(
      `/tsc/${taskId}/polyline`,options
    );
  }


export const getGetTscPolyLineQueryKey = (taskId: string,) => {
    return [`/tsc/${taskId}/polyline`] as const;
    }

    
export const getGetTscPolyLineQueryOptions = <TData = Awaited<ReturnType<typeof getTscPolyLine>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscPolyLine>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTscPolyLineQueryKey(taskId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTscPolyLine>>> = ({ signal }) => getTscPolyLine(taskId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTscPolyLine>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTscPolyLineQueryResult = NonNullable<Awaited<ReturnType<typeof getTscPolyLine>>>
export type GetTscPolyLineQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the polyline result of a TSC inference
 */
export const useGetTscPolyLine = <TData = Awaited<ReturnType<typeof getTscPolyLine>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscPolyLine>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTscPolyLineQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets the segmented gingiva structure in the STL format
 */
export const getTscGingivaStructure = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    
    return axios.get(
      `/tsc/${taskId}/gingiva`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetTscGingivaStructureQueryKey = (taskId: string,) => {
    return [`/tsc/${taskId}/gingiva`] as const;
    }

    
export const getGetTscGingivaStructureQueryOptions = <TData = Awaited<ReturnType<typeof getTscGingivaStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscGingivaStructure>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTscGingivaStructureQueryKey(taskId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTscGingivaStructure>>> = ({ signal }) => getTscGingivaStructure(taskId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTscGingivaStructure>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTscGingivaStructureQueryResult = NonNullable<Awaited<ReturnType<typeof getTscGingivaStructure>>>
export type GetTscGingivaStructureQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the segmented gingiva structure in the STL format
 */
export const useGetTscGingivaStructure = <TData = Awaited<ReturnType<typeof getTscGingivaStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscGingivaStructure>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTscGingivaStructureQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets the segmented tooth STL with original shape in the STL format
 */
export const getTscOriginalShape = (
    taskId: string,
    stlId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    
    return axios.get(
      `/tsc/${taskId}/original/${stlId}`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetTscOriginalShapeQueryKey = (taskId: string,
    stlId: string,) => {
    return [`/tsc/${taskId}/original/${stlId}`] as const;
    }

    
export const getGetTscOriginalShapeQueryOptions = <TData = Awaited<ReturnType<typeof getTscOriginalShape>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    stlId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscOriginalShape>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTscOriginalShapeQueryKey(taskId,stlId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTscOriginalShape>>> = ({ signal }) => getTscOriginalShape(taskId,stlId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId && stlId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTscOriginalShape>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTscOriginalShapeQueryResult = NonNullable<Awaited<ReturnType<typeof getTscOriginalShape>>>
export type GetTscOriginalShapeQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the segmented tooth STL with original shape in the STL format
 */
export const useGetTscOriginalShape = <TData = Awaited<ReturnType<typeof getTscOriginalShape>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    stlId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscOriginalShape>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTscOriginalShapeQueryOptions(taskId,stlId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets the segmented tooth STL with predicted shape in the STL format
 */
export const getTscPredictedShape = (
    taskId: string,
    stlId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    
    return axios.get(
      `/tsc/${taskId}/predicted/${stlId}`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetTscPredictedShapeQueryKey = (taskId: string,
    stlId: string,) => {
    return [`/tsc/${taskId}/predicted/${stlId}`] as const;
    }

    
export const getGetTscPredictedShapeQueryOptions = <TData = Awaited<ReturnType<typeof getTscPredictedShape>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    stlId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscPredictedShape>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTscPredictedShapeQueryKey(taskId,stlId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTscPredictedShape>>> = ({ signal }) => getTscPredictedShape(taskId,stlId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId && stlId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTscPredictedShape>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTscPredictedShapeQueryResult = NonNullable<Awaited<ReturnType<typeof getTscPredictedShape>>>
export type GetTscPredictedShapeQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the segmented tooth STL with predicted shape in the STL format
 */
export const useGetTscPredictedShape = <TData = Awaited<ReturnType<typeof getTscPredictedShape>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    stlId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscPredictedShape>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTscPredictedShapeQueryOptions(taskId,stlId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets the segmented tooth STL with stitched shape in the STL format
 */
export const getTscStitchedShape = (
    taskId: string,
    stlId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    
    return axios.get(
      `/tsc/${taskId}/stitched/${stlId}`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetTscStitchedShapeQueryKey = (taskId: string,
    stlId: string,) => {
    return [`/tsc/${taskId}/stitched/${stlId}`] as const;
    }

    
export const getGetTscStitchedShapeQueryOptions = <TData = Awaited<ReturnType<typeof getTscStitchedShape>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    stlId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscStitchedShape>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTscStitchedShapeQueryKey(taskId,stlId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTscStitchedShape>>> = ({ signal }) => getTscStitchedShape(taskId,stlId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId && stlId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTscStitchedShape>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTscStitchedShapeQueryResult = NonNullable<Awaited<ReturnType<typeof getTscStitchedShape>>>
export type GetTscStitchedShapeQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the segmented tooth STL with stitched shape in the STL format
 */
export const useGetTscStitchedShape = <TData = Awaited<ReturnType<typeof getTscStitchedShape>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    stlId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTscStitchedShape>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTscStitchedShapeQueryOptions(taskId,stlId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets the landmarks of the tooth with the specified FDI
 */
export const getLandmarks = (
    taskId: string,
    fdi: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Landmarks>> => {
    
    return axios.get(
      `/tsc/${taskId}/landmarks/${fdi}`,options
    );
  }


export const getGetLandmarksQueryKey = (taskId: string,
    fdi: string,) => {
    return [`/tsc/${taskId}/landmarks/${fdi}`] as const;
    }

    
export const getGetLandmarksQueryOptions = <TData = Awaited<ReturnType<typeof getLandmarks>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    fdi: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLandmarks>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLandmarksQueryKey(taskId,fdi);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLandmarks>>> = ({ signal }) => getLandmarks(taskId,fdi, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId && fdi), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLandmarks>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLandmarksQueryResult = NonNullable<Awaited<ReturnType<typeof getLandmarks>>>
export type GetLandmarksQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the landmarks of the tooth with the specified FDI
 */
export const useGetLandmarks = <TData = Awaited<ReturnType<typeof getLandmarks>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    fdi: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLandmarks>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLandmarksQueryOptions(taskId,fdi,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets the pose of the tooth with the specified FDI
 */
export const getPose = (
    taskId: string,
    fdi: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Pose>> => {
    
    return axios.get(
      `/tsc/${taskId}/pose/${fdi}`,options
    );
  }


export const getGetPoseQueryKey = (taskId: string,
    fdi: string,) => {
    return [`/tsc/${taskId}/pose/${fdi}`] as const;
    }

    
export const getGetPoseQueryOptions = <TData = Awaited<ReturnType<typeof getPose>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    fdi: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPose>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPoseQueryKey(taskId,fdi);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPose>>> = ({ signal }) => getPose(taskId,fdi, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId && fdi), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPose>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPoseQueryResult = NonNullable<Awaited<ReturnType<typeof getPose>>>
export type GetPoseQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the pose of the tooth with the specified FDI
 */
export const useGetPose = <TData = Awaited<ReturnType<typeof getPose>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    fdi: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPose>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPoseQueryOptions(taskId,fdi,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



