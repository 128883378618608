/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Promaton HTTP API
 * This API allows you to upload STL and 3D CBCT images for segmentation.

## Tasks
Because inferences take longer than some proxy servers allow open
connection for, we accept all uploads as a `task` which you
can monitor until it reaches completion.

Monitoring can be done via polling.

## Authentication

Authentication is done by setting your api key in the `Authorization`
header, prefixed with `Bearer`, like so: `Bearer <yourtoken>`.

Depending on your API token, you can have access to either OSS, CBCT,
ACD or all.

## Uploading your first STL
You can upload your first STL using the following curl command:

```sh
curl -X POST "https://api.promaton.com/oss/lower" \
    -H "Content-Type: model/stl" \
    --data-binary "@path_to_stl.lower.stl" \
    --header "Authorization: Bearer <your token>" \
    --http1.1 --verbose \
```

After that, you can get the status of your task using
the id of the task returned to you, in the following
curl command:

```sh
curl "https://api.promaton.com/oss/{your task id}" \
    --header "Authorization: Bearer <your token>" \
    --http1.1\
```

Until it reaches the completed state.

You can then use either the `stl` output, or `polyline` output endpoints
to get the result. If an error occurs, the state will switch to `errored`.

## Response times

We shut down our servers whenever possible to reduce cost. You therefore
may experience a "cold start" when first calling our API. This takes
at most five minutes. After that, the response times should be on par.

## Task pagination
All tasks offer a pagination endpoint. Because tasks can be added while
you are paginating, we work with a pointer-based approach using a task id.

If you want to start paginating from the most recent task to older tasks,
you can call (for example) `/cbct?sort_order=desc&limit=10`. Then, for the
next 10 items, you take the id last item of the previous result, and send
that along as the `from_id` like this:
`/cbct?sort_order=desc&limit=15&from_id=ckbulxexk0016xapiz8h3g2pn`.

## Known issues
- Currently, there is an issue with HTTP-v2 and curl. Please use
  the `--http1.1` command to circumvent it.

## Changelog
The changelog has moved and is available <a href="/docs/changelog">here</a>

## Patents
The following products are protected by patents in the U.S. and elsewhere, all
or some of which are third party intellectual property rights. This website is
provided to satisfy the virtual patent marking provisions of various jurisdictions
including the virtual patent marking provisions of the America Invents Act. The
following list of products may not be all inclusive, and other products not
listed here may be protected by one or more patents.

`US Patent Number 11,379,975, US Patent Number 11,568,533` - for Segmentation of 3D anatomical structures

`US Patent Number 11,455,774` - for Root Shape Prediction

`US Patent Number 12,229,993` - Alignment of 3D tooth surfaces using deep learning
 * OpenAPI spec version: 10.17.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  CbctListTasks,
  CbctTask,
  CreateCbctFilledJawParams,
  CreateCbctParams,
  ErrorResponse,
  ForbiddenResponse,
  GetCbctFilledJawTasksParams,
  GetCbctTasksParams,
  GoneResponse,
  NotFoundResponse,
  UnauthorizedResponse
} from '../model'




/**
 * Retrieves and returns a list of all CBCT tasks created by the current authenticated user.
 * @summary Gets all CBCT tasks for the current authenticated user
 */
export const getCbctTasks = (
    params?: GetCbctTasksParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CbctListTasks>> => {
    
    return axios.get(
      `/cbct`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetCbctTasksQueryKey = (params?: GetCbctTasksParams,) => {
    return [`/cbct`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCbctTasksQueryOptions = <TData = Awaited<ReturnType<typeof getCbctTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(params?: GetCbctTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCbctTasks>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCbctTasksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCbctTasks>>> = ({ signal }) => getCbctTasks(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCbctTasks>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCbctTasksQueryResult = NonNullable<Awaited<ReturnType<typeof getCbctTasks>>>
export type GetCbctTasksQueryError = AxiosError<ErrorResponse | UnauthorizedResponse>

/**
 * @summary Gets all CBCT tasks for the current authenticated user
 */
export const useGetCbctTasks = <TData = Awaited<ReturnType<typeof getCbctTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(
 params?: GetCbctTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCbctTasks>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCbctTasksQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Starts inference on any CBCT X-Ray. We support a stack of Dicoms in multiple `.dcm`
files, or all slices packed together in one `.dcm` file. In either case,
the file(s) should be packed into a ZIP file. The entire zip file will be scanned
for `.dcm` and `.dic` files (case insensitive),
and those will be regarded as a single Dicom stack.
 * @summary Create CBCT X-Ray inference
 */
export const createCbct = (
    createCbctBody: Blob,
    params?: CreateCbctParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CbctTask>> => {
    
    return axios.post(
      `/cbct`,
      createCbctBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateCbctMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCbct>>, TError,{data: Blob;params?: CreateCbctParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createCbct>>, TError,{data: Blob;params?: CreateCbctParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCbct>>, {data: Blob;params?: CreateCbctParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createCbct(data,params,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateCbctMutationResult = NonNullable<Awaited<ReturnType<typeof createCbct>>>
    export type CreateCbctMutationBody = Blob
    export type CreateCbctMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse>

    /**
 * @summary Create CBCT X-Ray inference
 */
export const useCreateCbct = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCbct>>, TError,{data: Blob;params?: CreateCbctParams}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getCreateCbctMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Retrieves and returns a list of all CBCT Filled Jaw tasks created by the current authenticated user.
 * @summary Gets all CBCT Filled Jaw tasks for the current authenticated user
 */
export const getCbctFilledJawTasks = (
    params?: GetCbctFilledJawTasksParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CbctListTasks>> => {
    
    return axios.get(
      `/cbct/filled-jaw`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetCbctFilledJawTasksQueryKey = (params?: GetCbctFilledJawTasksParams,) => {
    return [`/cbct/filled-jaw`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCbctFilledJawTasksQueryOptions = <TData = Awaited<ReturnType<typeof getCbctFilledJawTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(params?: GetCbctFilledJawTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCbctFilledJawTasks>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCbctFilledJawTasksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCbctFilledJawTasks>>> = ({ signal }) => getCbctFilledJawTasks(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCbctFilledJawTasks>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCbctFilledJawTasksQueryResult = NonNullable<Awaited<ReturnType<typeof getCbctFilledJawTasks>>>
export type GetCbctFilledJawTasksQueryError = AxiosError<ErrorResponse | UnauthorizedResponse>

/**
 * @summary Gets all CBCT Filled Jaw tasks for the current authenticated user
 */
export const useGetCbctFilledJawTasks = <TData = Awaited<ReturnType<typeof getCbctFilledJawTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(
 params?: GetCbctFilledJawTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCbctFilledJawTasks>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCbctFilledJawTasksQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Starts inference on any CBCT X-Ray and performs Jaw Segmentation only. We support a stack of Dicoms in multiple `.dcm`
files, or all slices packed together in one `.dcm` file. In either case,
the file(s) should be packed into a ZIP file. The entire zip file will be scanned
for `.dcm` and `.dic` files (case insensitive),
and those will be regarded as a single Dicom stack.
 * @summary Create CBCT Filled Jaw X-Ray inference
 */
export const createCbctFilledJaw = (
    createCbctFilledJawBody: Blob,
    params?: CreateCbctFilledJawParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CbctTask>> => {
    
    return axios.post(
      `/cbct/filled-jaw`,
      createCbctFilledJawBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateCbctFilledJawMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCbctFilledJaw>>, TError,{data: Blob;params?: CreateCbctFilledJawParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createCbctFilledJaw>>, TError,{data: Blob;params?: CreateCbctFilledJawParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCbctFilledJaw>>, {data: Blob;params?: CreateCbctFilledJawParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createCbctFilledJaw(data,params,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateCbctFilledJawMutationResult = NonNullable<Awaited<ReturnType<typeof createCbctFilledJaw>>>
    export type CreateCbctFilledJawMutationBody = Blob
    export type CreateCbctFilledJawMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse>

    /**
 * @summary Create CBCT Filled Jaw X-Ray inference
 */
export const useCreateCbctFilledJaw = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCbctFilledJaw>>, TError,{data: Blob;params?: CreateCbctFilledJawParams}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getCreateCbctFilledJawMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets the task object for a CBCT inference
 */
export const getCbct = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CbctTask>> => {
    
    return axios.get(
      `/cbct/${taskId}`,options
    );
  }


export const getGetCbctQueryKey = (taskId: string,) => {
    return [`/cbct/${taskId}`] as const;
    }

    
export const getGetCbctQueryOptions = <TData = Awaited<ReturnType<typeof getCbct>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCbct>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCbctQueryKey(taskId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCbct>>> = ({ signal }) => getCbct(taskId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCbct>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCbctQueryResult = NonNullable<Awaited<ReturnType<typeof getCbct>>>
export type GetCbctQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the task object for a CBCT inference
 */
export const useGetCbct = <TData = Awaited<ReturnType<typeof getCbct>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCbct>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCbctQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Deletes a task
 */
export const deleteCbctTask = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.delete(
      `/cbct/${taskId}`,options
    );
  }



export const getDeleteCbctTaskMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCbctTask>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCbctTask>>, TError,{taskId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCbctTask>>, {taskId: string}> = (props) => {
          const {taskId} = props ?? {};

          return  deleteCbctTask(taskId,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteCbctTaskMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCbctTask>>>
    
    export type DeleteCbctTaskMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

    /**
 * @summary Deletes a task
 */
export const useDeleteCbctTask = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCbctTask>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getDeleteCbctTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets an individual structure in the desired format. STL by default.
 */
export const getCbctIndividualStructure = (
    taskId: string,
    label: 'tooth' | 'jaw' | 'implant' | 'crown' | 'filled_jaw' | 'filled_sockets_jaw' | 'maxillary_sinus' | 'incisive_canal' | 'nasal_passage',
    region: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    
    return axios.get(
      `/cbct/${taskId}/findings/${label}/${region}`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetCbctIndividualStructureQueryKey = (taskId: string,
    label: 'tooth' | 'jaw' | 'implant' | 'crown' | 'filled_jaw' | 'filled_sockets_jaw' | 'maxillary_sinus' | 'incisive_canal' | 'nasal_passage',
    region: string,) => {
    return [`/cbct/${taskId}/findings/${label}/${region}`] as const;
    }

    
export const getGetCbctIndividualStructureQueryOptions = <TData = Awaited<ReturnType<typeof getCbctIndividualStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    label: 'tooth' | 'jaw' | 'implant' | 'crown' | 'filled_jaw' | 'filled_sockets_jaw' | 'maxillary_sinus' | 'incisive_canal' | 'nasal_passage',
    region: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCbctIndividualStructure>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCbctIndividualStructureQueryKey(taskId,label,region);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCbctIndividualStructure>>> = ({ signal }) => getCbctIndividualStructure(taskId,label,region, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId && label && region), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCbctIndividualStructure>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCbctIndividualStructureQueryResult = NonNullable<Awaited<ReturnType<typeof getCbctIndividualStructure>>>
export type GetCbctIndividualStructureQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets an individual structure in the desired format. STL by default.
 */
export const useGetCbctIndividualStructure = <TData = Awaited<ReturnType<typeof getCbctIndividualStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    label: 'tooth' | 'jaw' | 'implant' | 'crown' | 'filled_jaw' | 'filled_sockets_jaw' | 'maxillary_sinus' | 'incisive_canal' | 'nasal_passage',
    region: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCbctIndividualStructure>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCbctIndividualStructureQueryOptions(taskId,label,region,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets an individual unassigned structure in the desired format. Defaults to STL.
 */
export const getCbctIndividualStructureWithUnassignedId = (
    taskId: string,
    label: 'tooth' | 'jaw' | 'implant' | 'crown' | 'filled_jaw' | 'maxillary_sinus' | 'incisive_canal' | 'nasal_passage',
    region: string,
    unassignedId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    
    return axios.get(
      `/cbct/${taskId}/findings/${label}/${region}/${unassignedId}`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetCbctIndividualStructureWithUnassignedIdQueryKey = (taskId: string,
    label: 'tooth' | 'jaw' | 'implant' | 'crown' | 'filled_jaw' | 'maxillary_sinus' | 'incisive_canal' | 'nasal_passage',
    region: string,
    unassignedId: string,) => {
    return [`/cbct/${taskId}/findings/${label}/${region}/${unassignedId}`] as const;
    }

    
export const getGetCbctIndividualStructureWithUnassignedIdQueryOptions = <TData = Awaited<ReturnType<typeof getCbctIndividualStructureWithUnassignedId>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    label: 'tooth' | 'jaw' | 'implant' | 'crown' | 'filled_jaw' | 'maxillary_sinus' | 'incisive_canal' | 'nasal_passage',
    region: string,
    unassignedId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCbctIndividualStructureWithUnassignedId>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCbctIndividualStructureWithUnassignedIdQueryKey(taskId,label,region,unassignedId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCbctIndividualStructureWithUnassignedId>>> = ({ signal }) => getCbctIndividualStructureWithUnassignedId(taskId,label,region,unassignedId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId && label && region && unassignedId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCbctIndividualStructureWithUnassignedId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCbctIndividualStructureWithUnassignedIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCbctIndividualStructureWithUnassignedId>>>
export type GetCbctIndividualStructureWithUnassignedIdQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets an individual unassigned structure in the desired format. Defaults to STL.
 */
export const useGetCbctIndividualStructureWithUnassignedId = <TData = Awaited<ReturnType<typeof getCbctIndividualStructureWithUnassignedId>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    label: 'tooth' | 'jaw' | 'implant' | 'crown' | 'filled_jaw' | 'maxillary_sinus' | 'incisive_canal' | 'nasal_passage',
    region: string,
    unassignedId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCbctIndividualStructureWithUnassignedId>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCbctIndividualStructureWithUnassignedIdQueryOptions(taskId,label,region,unassignedId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



