/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Promaton HTTP API
 * This API allows you to upload STL and 3D CBCT images for segmentation.

## Tasks
Because inferences take longer than some proxy servers allow open
connection for, we accept all uploads as a `task` which you
can monitor until it reaches completion.

Monitoring can be done via polling.

## Authentication

Authentication is done by setting your api key in the `Authorization`
header, prefixed with `Bearer`, like so: `Bearer <yourtoken>`.

Depending on your API token, you can have access to either OSS, CBCT,
ACD or all.

## Uploading your first STL
You can upload your first STL using the following curl command:

```sh
curl -X POST "https://api.promaton.com/oss/lower" \
    -H "Content-Type: model/stl" \
    --data-binary "@path_to_stl.lower.stl" \
    --header "Authorization: Bearer <your token>" \
    --http1.1 --verbose \
```

After that, you can get the status of your task using
the id of the task returned to you, in the following
curl command:

```sh
curl "https://api.promaton.com/oss/{your task id}" \
    --header "Authorization: Bearer <your token>" \
    --http1.1\
```

Until it reaches the completed state.

You can then use either the `stl` output, or `polyline` output endpoints
to get the result. If an error occurs, the state will switch to `errored`.

## Response times

We shut down our servers whenever possible to reduce cost. You therefore
may experience a "cold start" when first calling our API. This takes
at most five minutes. After that, the response times should be on par.

## Task pagination
All tasks offer a pagination endpoint. Because tasks can be added while
you are paginating, we work with a pointer-based approach using a task id.

If you want to start paginating from the most recent task to older tasks,
you can call (for example) `/cbct?sort_order=desc&limit=10`. Then, for the
next 10 items, you take the id last item of the previous result, and send
that along as the `from_id` like this:
`/cbct?sort_order=desc&limit=15&from_id=ckbulxexk0016xapiz8h3g2pn`.

## Known issues
- Currently, there is an issue with HTTP-v2 and curl. Please use
  the `--http1.1` command to circumvent it.

## Changelog
The changelog has moved and is available <a href="/docs/changelog">here</a>

## Patents
The following products are protected by patents in the U.S. and elsewhere, all
or some of which are third party intellectual property rights. This website is
provided to satisfy the virtual patent marking provisions of various jurisdictions
including the virtual patent marking provisions of the America Invents Act. The
following list of products may not be all inclusive, and other products not
listed here may be protected by one or more patents.

`US Patent Number 11,379,975, US Patent Number 11,568,533` - for Segmentation of 3D anatomical structures

`US Patent Number 11,455,774` - for Root Shape Prediction

`US Patent Number 12,229,993` - Alignment of 3D tooth surfaces using deep learning
 * OpenAPI spec version: 10.17.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BadRequestResponse,
  ConflictResponse,
  Feedback,
  FeedbackRequestBody,
  NotFoundResponse,
  UnauthorizedResponse
} from '../model'




/**
 * @summary Submit feedback for a specific task
 */
export const submitFeedback = (
    taskId: string,
    feedbackRequestBody: FeedbackRequestBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Feedback>> => {
    
    return axios.post(
      `/feedback/${taskId}`,
      feedbackRequestBody,options
    );
  }



export const getSubmitFeedbackMutationOptions = <TError = AxiosError<BadRequestResponse | UnauthorizedResponse | ConflictResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitFeedback>>, TError,{taskId: string;data: FeedbackRequestBody}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof submitFeedback>>, TError,{taskId: string;data: FeedbackRequestBody}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitFeedback>>, {taskId: string;data: FeedbackRequestBody}> = (props) => {
          const {taskId,data} = props ?? {};

          return  submitFeedback(taskId,data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SubmitFeedbackMutationResult = NonNullable<Awaited<ReturnType<typeof submitFeedback>>>
    export type SubmitFeedbackMutationBody = FeedbackRequestBody
    export type SubmitFeedbackMutationError = AxiosError<BadRequestResponse | UnauthorizedResponse | ConflictResponse>

    /**
 * @summary Submit feedback for a specific task
 */
export const useSubmitFeedback = <TError = AxiosError<BadRequestResponse | UnauthorizedResponse | ConflictResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitFeedback>>, TError,{taskId: string;data: FeedbackRequestBody}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getSubmitFeedbackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get feedback for a specific task
 */
export const getFeedback = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Feedback>> => {
    
    return axios.get(
      `/feedback/${taskId}`,options
    );
  }


export const getGetFeedbackQueryKey = (taskId: string,) => {
    return [`/feedback/${taskId}`] as const;
    }

    
export const getGetFeedbackQueryOptions = <TData = Awaited<ReturnType<typeof getFeedback>>, TError = AxiosError<BadRequestResponse | UnauthorizedResponse | NotFoundResponse>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFeedback>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedbackQueryKey(taskId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedback>>> = ({ signal }) => getFeedback(taskId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedback>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeedbackQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedback>>>
export type GetFeedbackQueryError = AxiosError<BadRequestResponse | UnauthorizedResponse | NotFoundResponse>

/**
 * @summary Get feedback for a specific task
 */
export const useGetFeedback = <TData = Awaited<ReturnType<typeof getFeedback>>, TError = AxiosError<BadRequestResponse | UnauthorizedResponse | NotFoundResponse>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFeedback>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFeedbackQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



