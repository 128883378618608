import { FileType, ViewerObjectMap } from "@promaton/scan-viewer";
import { sentenceCase } from "change-case";
import { Matrix3, Matrix4 } from "three";
import z from "zod";

const OssToothRegex = /(tooth)?\.?[0-9]{2}$/i;
const OssScanRegex = /(scan)?\.?(upper|lower)(_.*)?$/i;
const OssGingivaRegex = /gingiva/i;
const OssResultRegex = /result$/i;
const OssBboxRegex = /bboxes/i;
const OssResultSchema = z
  .object({
    orientation: z.array(z.array(z.number()).length(3)).length(3),
  })
  .passthrough();

/** Somewhat hacky way of detecting we are looking at an OSS output. */
export const checkIsOSS = async (objects: ViewerObjectMap) => {
  const entries = Object.entries(objects);
  const hasTransition = !!entries.find(([key]) =>
    key.match(/(transition|polyline)/i)
  );
  const hasBbox = !!entries.find(([key]) => key.match(OssBboxRegex));
  const hasGingiva = !!entries.find(([key]) => key.match(OssGingivaRegex));

  const result = entries.find(
    ([key, obj]) =>
      key.match(OssResultRegex) && obj.objectType === FileType.JSON
  )?.[1].url;

  if (result && !Array.isArray(result)) {
    const isOSSResult = (
      await OssResultSchema.safeParseAsync(await (await fetch(result)).json())
    ).success;
    if (isOSSResult) return true;
  }

  if (!hasTransition && !hasBbox && !hasGingiva) return false;

  const noImage = !entries.find(
    ([_, value]) =>
      value.objectType === FileType.DICOM || value.objectType === FileType.XRAY
  );
  if (!noImage) return false;

  const hasDotNotation = !!entries.find(
    ([key]) => key.match(OssToothRegex) || key.match(OssScanRegex)
  );
  if (!hasDotNotation) return false;

  return true;
};

export const applyOSSConvention = async (objects: ViewerObjectMap) => {
  const result = Object.entries(objects).find(([id]) =>
    id.match(OssResultRegex)
  )?.[1].url as string | undefined;
  let transform: Matrix4 | undefined;

  try {
    const orientation = result
      ? OssResultSchema.parse(await (await fetch(result)).json())
      : undefined;

    if (orientation) {
      transform = new Matrix4()
        .setFromMatrix3(new Matrix3().fromArray(orientation.orientation.flat()))
        .invert();
    }
  } catch (error) {
    console.warn("Incorrect result file");
  }

  const hasGingiva = !!Object.entries(objects).find(([id]) =>
    id.match(OssGingivaRegex)
  );

  Object.entries(objects).map(([id, viewerObject]) => {
    if (transform) {
      if (viewerObject.objectType !== FileType.PLY) {
        viewerObject.transform = transform;
      }
    }

    if (!viewerObject.group) {
      viewerObject.group = sentenceCase(
        id.split("/").at(-1)?.split(".").at(0) ?? ""
      );
    }

    if (viewerObject.group?.includes("Scan")) {
      viewerObject.opacity = 0.3;
      viewerObject.hidden = hasGingiva;
    } else if (OssResultRegex.test(id)) {
      viewerObject.isMetadata = true;
    }
  });
};
