import { useFrame, useThree } from "@react-three/fiber";
import { FC, memo, useMemo } from "react";
import { Euler, Vector3 } from "three";

const position = new Vector3();
const rotation = new Euler(0, 0, 0);

export const PresentationStage: FC<{}> = memo(() => {
  const camera = useThree((s) => s.camera);
  const control = useThree(
    (s) => s.controls as any as { target: Vector3; enabled: boolean }
  );
  const invalidate = useThree((s) => s.invalidate);
  const distance = 500;

  const animate = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("animate") !== "false";
  }, []);

  useFrame(() => {
    if (!animate) {
      control.enabled = true;
      return;
    }

    control.enabled = false;

    rotation.y += 0.01;

    position
      .set(0, 0, 1)
      .applyEuler(rotation)
      .multiplyScalar(distance)
      .add(control.target);

    camera.position.copy(position);
    camera.rotation.copy(rotation);

    invalidate();
  });

  return null;
});
