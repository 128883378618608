import {
  Alert,
  Breadcrumbs,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  Link as Crumb,
  ListItem,
  ListItemText,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AlertCircle,
  BoundingBox,
  CaretLeft,
  CaretRight,
  Check,
  Cloud,
  CrossCircle,
  FolderAlt,
  SelectCircle,
} from "@promaton/icons";
import { useObjects } from "@promaton/scan-viewer";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import ga4 from "react-ga4";
import { Helmet } from "react-helmet";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { Link, useLocation } from "wouter";
import { useShallow } from "zustand/shallow";

import { createRouteLink, routes } from "../helpers/routes";
import { trpc } from "../hooks/trpc";
import { useAppState } from "../stores/useAppState";
import { useRecents } from "../stores/useRecents";
import { BrowserItem } from "./BrowserItem";
import { EmptyState } from "./EmptyState";
import { FavoriteToggle } from "./FavoriteToggle";
import { LoginDialog } from "./LoginDialog";
import { COMPARISON_QUERY_PARAM } from "./S3LoadingDialog";

export const S3FolderBrowser: FC<
  {
    bucket: string;
    folder: string;

    /** Indicates which page is currently active */
    activePrefix?: string;

    /** If the browser is used as a popup. */
    compact?: boolean;

    comparisonFolder?: string;

    onCompare?: (prefix: string) => void;
  } & StackProps
> = ({
  bucket,
  folder = "",
  activePrefix,
  comparisonFolder,
  onCompare,
  compact = true,
  ...rest
}) => {
  const [currentFolder, setCurrentFolder] = useState(folder);
  const [mode, setMode] = useState<"default" | "compare">("default");
  const directoryQuery = trpc.storage.listSubDirectories.useInfiniteQuery(
    {
      bucket,
      folder: currentFolder,
    },
    {
      getNextPageParam: (lastPage) => lastPage.NextMarker,
    }
  );

  const directories = useMemo(
    () =>
      directoryQuery.data?.pages.reduce(
        (acc, page) =>
          acc.concat(
            page.CommonPrefixes || [],
            page.Contents?.map((i) => ({
              Key: i.Key,
            })) || []
          ),
        [] as { Prefix?: string; Key?: string }[]
      ),
    [directoryQuery.data]
  );

  const [_, setLocation] = useLocation();
  const setObjects = useObjects((s) => s.setObjects);
  const virtuoso = useRef<VirtuosoHandle>(null);
  const path = currentFolder.split("/");
  const parent = path.at(-1) || bucket;
  const recents = useRecents(
    useShallow((s) => new Set(s.recents.map((recent) => recent.path)))
  );

  useEffect(() => {
    if (!virtuoso.current || !activePrefix || !directories) return;
    const index = directories.findIndex((i) => i.Prefix === activePrefix);

    if (index < 0) return;

    setTimeout(() => {
      virtuoso.current?.scrollToIndex({
        index,
        align: "center",
      });
    }, 30);
  }, [directories]);

  const goUp = () => {
    const newPath = currentFolder.split("/");
    newPath.splice(-1, 1);
    setCurrentFolder(newPath.join("/"));
  };

  useEffect(() => {
    !compact &&
      setLocation(
        createRouteLink(routes.browseS3, { bucket, folder: currentFolder })
      );
  }, [compact, currentFolder]);

  useEffect(() => {
    !compact && setCurrentFolder(folder);
  }, [compact, folder]);

  const viewFolder = (link: string) => {
    setObjects({});
    let _link = link;
    if (comparisonFolder) {
      _link = `${link}?${COMPARISON_QUERY_PARAM}=${encodeURIComponent(
        comparisonFolder
      )}`;
    }

    setLocation(_link);
    ga4.event({
      category: "browsing",
      action: "Folder opened via S3 browser",
    });
  };

  const folderContainsCurrentPrefix = useMemo(() => {
    if (!activePrefix || !directories) return false;
    const folderName = activePrefix.split("/").at(-2);
    if (!folderName) return false;
    return directories.some((i) => i.Prefix?.includes(folderName));
  }, [activePrefix, directories]);

  const session = useAppState((s) => s.session);

  const currentFolderLink = useMemo(
    () =>
      createRouteLink(routes.s3, {
        bucket,
        folder: currentFolder,
      }),
    [bucket, currentFolder]
  );

  if (!session) {
    return (
      <LoginDialog
        title="Log in to browse S3"
        onClose={() => {
          setLocation("/");
        }}
        open
      />
    );
  }

  return (
    <Stack
      {...rest}
      sx={{
        width: 400,
        height: 600,
        maxHeight: "70vh",
        overflowY: "hidden",
        ...rest.sx,
      }}
    >
      {!compact && (
        <Helmet>
          <title>{parent || bucket} · Promaton Viewer</title>
        </Helmet>
      )}
      <ListItem
        secondaryAction={
          !onCompare ? null : mode === "default" && !comparisonFolder ? (
            <Tooltip title="Select a parent folder to compare contents to">
              <Button
                variant="text"
                sx={{ mr: 3 }}
                onClick={() => {
                  setMode("compare");
                  setCurrentFolder("");
                }}
              >
                Compare
              </Button>
            </Tooltip>
          ) : mode === "compare" ? (
            <Button
              variant="contained"
              sx={{ mr: 3 }}
              disabled={!folderContainsCurrentPrefix}
              onClick={() => {
                onCompare(currentFolder);
                setMode("default");
              }}
            >
              Select
            </Button>
          ) : null
        }
        disableGutters
        sx={
          compact
            ? {}
            : {
                margin: "0 auto",
                paddingRight: 2.5,
              }
        }
      >
        <IconButton
          sx={{
            marginLeft: 1,
            marginRight: 1,
          }}
          onClick={goUp}
          disabled={!currentFolder}
        >
          {currentFolder ? <CaretLeft /> : <Cloud />}
        </IconButton>
        {compact ? (
          <ListItemText
            title={path.join("/")}
            primary={parent}
            primaryTypographyProps={{
              variant: compact ? undefined : "h5",
              fontWeight: compact ? undefined : "bold",
              sx: {
                userSelect: "none",
                width: "80%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              },
            }}
          />
        ) : (
          <Breadcrumbs maxItems={3} sx={{ flex: 1 }}>
            <Link
              href={createRouteLink(routes.browseS3, { bucket, folder: "" })}
            >
              <Crumb
                color={!path[0] ? "text.primary" : "inherit"}
                underline="hover"
                href=""
              >
                {bucket}
              </Crumb>
            </Link>
            {path.map((item, i) => {
              const folder = path.slice(0, i + 1).join("/");
              const link = createRouteLink(routes.browseS3, { folder, bucket });
              if (!item) return null;
              return (
                <Link href={link} key={link}>
                  <Crumb
                    color={i === path.length - 1 ? "text.primary" : "inherit"}
                    key={item}
                    underline="hover"
                    href="link"
                  >
                    {item}
                  </Crumb>
                </Link>
              );
            })}
          </Breadcrumbs>
        )}
        {!compact && (
          <>
            <Button
              href={currentFolderLink}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                currentFolderLink && viewFolder(currentFolderLink);
              }}
            >
              View
            </Button>

            <FavoriteToggle
              title={parent}
              path={`${bucket}/${currentFolder}`}
            />
          </>
        )}
      </ListItem>
      {compact && <Divider />}
      {mode === "compare" && (
        <Alert
          color="info"
          icon={<SelectCircle />}
          sx={{ borderRadius: "0 !important", mt: "0 !important" }}
        >
          Browse for a parent folder with the same folder structure to compare
          results to, then press &quot;Select&quot;. Results for from both
          folders will be loaded for each selected slug / scan name.
        </Alert>
      )}
      {comparisonFolder && onCompare && (
        <Alert
          color="warning"
          icon={<AlertCircle />}
          action={
            <Button
              color="warning"
              onClick={() => {
                onCompare("");
              }}
            >
              Exit
            </Button>
          }
          sx={{ borderRadius: "0 !important", mt: "0 !important" }}
        >
          <b>Comparison mode active</b>
          <br />
          Comparing to {comparisonFolder}
        </Alert>
      )}
      <div
        style={{
          flex: 1,
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        {directoryQuery.isLoading && <LinearProgress />}
        {directoryQuery.isError && (
          <EmptyState>
            <CrossCircle fontSize="large" color="error" />
            <Typography variant="h6">Failed to load your projects</Typography>
            <Typography variant="body2">
              Are you connected to the VPN?
            </Typography>
          </EmptyState>
        )}
        {directoryQuery.isSuccess && !directories?.length && (
          <EmptyState>
            <Typography>No sub-directories found</Typography>
            <Button
              href={currentFolderLink}
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                currentFolderLink && viewFolder(currentFolderLink);
              }}
            >
              View this folder
            </Button>
          </EmptyState>
        )}
        {directories?.length ? (
          <Virtuoso
            ref={virtuoso}
            style={{ height: "100%", width: "100%", overflowX: "hidden" }}
            data={directories}
            endReached={() => {
              directoryQuery.fetchNextPage();
            }}
            itemContent={(index, entry) => {
              if (!entry) return null;
              const link =
                entry.Prefix &&
                createRouteLink(routes.s3, {
                  bucket,
                  folder: entry.Prefix,
                });
              const isFile = !!entry?.Key;
              const name = isFile
                ? entry.Key?.split("/").at(-1)
                : entry.Prefix?.split("/").slice(-2)[0];
              const visited = link && recents.has(link);
              return (
                <BrowserItem
                  sx={compact ? {} : undefined}
                  key={entry.Prefix}
                  selected={!isFile && activePrefix === entry.Prefix}
                  link={link}
                  onClick={(e) => {
                    if (isFile) return;
                    if (!link || e.metaKey || e.ctrlKey) return;
                    e.preventDefault();

                    if (compact && mode === "default") {
                      viewFolder(link);
                    } else {
                      entry.Prefix &&
                        setCurrentFolder(entry.Prefix.slice(0, -1));
                    }
                  }}
                  icon={!compact && (isFile ? <BoundingBox /> : <FolderAlt />)}
                  title={name || "unknown name"}
                  secondary={
                    <>
                      {visited && (
                        <Check color="success" sx={{ marginRight: 1 }} />
                      )}
                      {compact && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            entry.Prefix &&
                              setCurrentFolder(entry.Prefix.slice(0, -1));
                          }}
                        >
                          <CaretRight />
                        </IconButton>
                      )}
                      {!compact && !isFile && (
                        <Stack flexDirection="row" gap={2}>
                          <Button
                            className="hide"
                            href={link}
                            variant="outlined"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              link && viewFolder(link);
                            }}
                          >
                            View
                          </Button>
                          <FavoriteToggle
                            title={name || "unkown"}
                            path={`${bucket}/${entry.Prefix?.slice(0, -1)}`}
                          />
                        </Stack>
                      )}
                    </>
                  }
                />
              );
            }}
          />
        ) : undefined}
      </div>
    </Stack>
  );
};
