import { IconButton, Tooltip } from "@mui/material";
import { ExpandRight } from "@promaton/icons";
import { useLocation } from "wouter";

export const PresentationModeExit = () => {
  const [location] = useLocation();

  return (
    <Tooltip title="Open in new tab">
      <IconButton
        href={location}
        target="_blank"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          margin: 1,
        }}
      >
        <ExpandRight />
      </IconButton>
    </Tooltip>
  );
};
