import { IconButton, Stack, Tooltip } from "@mui/material";
import { VisibilityOff, VisibilityOn } from "@promaton/icons";
import { useObjects } from "@promaton/scan-viewer";
import { FC, useCallback, useState } from "react";

export const LayerVisibilityControls: FC = () => {
  const [layerVisibility, setLayerVisibility] = useState(true);
  const objects = useObjects((s) => s.objects);
  const toggleObjectsVisibility = useObjects((s) => s.toggleObjectsVisibility);

  const onClickVisibilityIcon = useCallback(() => {
    setLayerVisibility(!layerVisibility);

    // Make sure that the visibility of all layers is toggled even when there are already toggled layers (e.g. hidden layers)
    // This is necessary because toggling works on a per-layer basis, so we need to make sure that all layers are toggled to the same visibility state
    const idsToToggle = Object.entries(objects)
      .filter(([_id, o]) => (layerVisibility ? !o?.hidden : o?.hidden))
      .map(([id]) => id);
    toggleObjectsVisibility(idsToToggle);
  }, [layerVisibility, objects, toggleObjectsVisibility]);

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        marginBottom: 1,
        borderBottom: (t) => `1px solid ${t.palette.grey[700]}`,
      }}
    >
      <Tooltip title="Toggle visibility of all layers">
        <IconButton sx={{ marginRight: 0.5 }} onClick={onClickVisibilityIcon}>
          {layerVisibility ? <VisibilityOn /> : <VisibilityOff />}
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
