/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Promaton HTTP API
 * This API allows you to upload STL and 3D CBCT images for segmentation.

## Tasks
Because inferences take longer than some proxy servers allow open
connection for, we accept all uploads as a `task` which you
can monitor until it reaches completion.

Monitoring can be done via polling.

## Authentication

Authentication is done by setting your api key in the `Authorization`
header, prefixed with `Bearer`, like so: `Bearer <yourtoken>`.

Depending on your API token, you can have access to either OSS, CBCT,
ACD or all.

## Uploading your first STL
You can upload your first STL using the following curl command:

```sh
curl -X POST "https://api.promaton.com/oss/lower" \
    -H "Content-Type: model/stl" \
    --data-binary "@path_to_stl.lower.stl" \
    --header "Authorization: Bearer <your token>" \
    --http1.1 --verbose \
```

After that, you can get the status of your task using
the id of the task returned to you, in the following
curl command:

```sh
curl "https://api.promaton.com/oss/{your task id}" \
    --header "Authorization: Bearer <your token>" \
    --http1.1\
```

Until it reaches the completed state.

You can then use either the `stl` output, or `polyline` output endpoints
to get the result. If an error occurs, the state will switch to `errored`.

## Response times

We shut down our servers whenever possible to reduce cost. You therefore
may experience a "cold start" when first calling our API. This takes
at most five minutes. After that, the response times should be on par.

## Task pagination
All tasks offer a pagination endpoint. Because tasks can be added while
you are paginating, we work with a pointer-based approach using a task id.

If you want to start paginating from the most recent task to older tasks,
you can call (for example) `/cbct?sort_order=desc&limit=10`. Then, for the
next 10 items, you take the id last item of the previous result, and send
that along as the `from_id` like this:
`/cbct?sort_order=desc&limit=15&from_id=ckbulxexk0016xapiz8h3g2pn`.

## Known issues
- Currently, there is an issue with HTTP-v2 and curl. Please use
  the `--http1.1` command to circumvent it.

## Changelog
The changelog has moved and is available <a href="/docs/changelog">here</a>

## Patents
The following products are protected by patents in the U.S. and elsewhere, all
or some of which are third party intellectual property rights. This website is
provided to satisfy the virtual patent marking provisions of various jurisdictions
including the virtual patent marking provisions of the America Invents Act. The
following list of products may not be all inclusive, and other products not
listed here may be protected by one or more patents.

`US Patent Number 11,379,975, US Patent Number 11,568,533` - for Segmentation of 3D anatomical structures

`US Patent Number 11,455,774` - for Root Shape Prediction

`US Patent Number 12,229,993` - Alignment of 3D tooth surfaces using deep learning
 * OpenAPI spec version: 10.17.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BadRequestResponse,
  CreateSippTaskParams,
  ErrorResponse,
  ForbiddenResponse,
  GetSippTasksParams,
  GoneResponse,
  NotFoundResponse,
  SippListTasks,
  SippRequestBody,
  SippTask,
  UnauthorizedResponse
} from '../model'




/**
 * Creates a new Single Implant Placement Planning API request, the user provides one
CBCT task, one Alignment task, an FDI number and optionally an ACD task.

 * @summary Create a new request for the Single Implant Placement Planning API. Optionally a time is provided for how long the results are stored for the user (to a 30 day maximum).
 */
export const createSippTask = (
    sippRequestBody: SippRequestBody,
    params: CreateSippTaskParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SippTask>> => {
    
    return axios.post(
      `/sipp`,
      sippRequestBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateSippTaskMutationOptions = <TError = AxiosError<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSippTask>>, TError,{data: SippRequestBody;params: CreateSippTaskParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createSippTask>>, TError,{data: SippRequestBody;params: CreateSippTaskParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSippTask>>, {data: SippRequestBody;params: CreateSippTaskParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createSippTask(data,params,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateSippTaskMutationResult = NonNullable<Awaited<ReturnType<typeof createSippTask>>>
    export type CreateSippTaskMutationBody = SippRequestBody
    export type CreateSippTaskMutationError = AxiosError<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse>

    /**
 * @summary Create a new request for the Single Implant Placement Planning API. Optionally a time is provided for how long the results are stored for the user (to a 30 day maximum).
 */
export const useCreateSippTask = <TError = AxiosError<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSippTask>>, TError,{data: SippRequestBody;params: CreateSippTaskParams}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getCreateSippTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get all Sigle Implant Placement Planning tasks for the current authenticated user
 */
export const getSippTasks = (
    params?: GetSippTasksParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SippListTasks>> => {
    
    return axios.get(
      `/sipp`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetSippTasksQueryKey = (params?: GetSippTasksParams,) => {
    return [`/sipp`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSippTasksQueryOptions = <TData = Awaited<ReturnType<typeof getSippTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(params?: GetSippTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSippTasks>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSippTasksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSippTasks>>> = ({ signal }) => getSippTasks(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSippTasks>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSippTasksQueryResult = NonNullable<Awaited<ReturnType<typeof getSippTasks>>>
export type GetSippTasksQueryError = AxiosError<ErrorResponse | UnauthorizedResponse>

/**
 * @summary Get all Sigle Implant Placement Planning tasks for the current authenticated user
 */
export const useGetSippTasks = <TData = Awaited<ReturnType<typeof getSippTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(
 params?: GetSippTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSippTasks>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSippTasksQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get the results for an Single Implant Placement Planning API call
 */
export const getSippTask = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SippTask>> => {
    
    return axios.get(
      `/sipp/${taskId}`,options
    );
  }


export const getGetSippTaskQueryKey = (taskId: string,) => {
    return [`/sipp/${taskId}`] as const;
    }

    
export const getGetSippTaskQueryOptions = <TData = Awaited<ReturnType<typeof getSippTask>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSippTask>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSippTaskQueryKey(taskId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSippTask>>> = ({ signal }) => getSippTask(taskId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSippTask>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSippTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getSippTask>>>
export type GetSippTaskQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Get the results for an Single Implant Placement Planning API call
 */
export const useGetSippTask = <TData = Awaited<ReturnType<typeof getSippTask>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSippTask>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSippTaskQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Deletes a task
 */
export const deleteSipp = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/sipp/${taskId}`,options
    );
  }



export const getDeleteSippMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSipp>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteSipp>>, TError,{taskId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSipp>>, {taskId: string}> = (props) => {
          const {taskId} = props ?? {};

          return  deleteSipp(taskId,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteSippMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSipp>>>
    
    export type DeleteSippMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

    /**
 * @summary Deletes a task
 */
export const useDeleteSipp = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSipp>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getDeleteSippMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets an individual structure in the STL format
 */
export const getSippImplantStlStructure = (
    taskId: string,
    structureId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    
    return axios.get(
      `/sipp/${taskId}/implant/stl/${structureId}`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetSippImplantStlStructureQueryKey = (taskId: string,
    structureId: string,) => {
    return [`/sipp/${taskId}/implant/stl/${structureId}`] as const;
    }

    
export const getGetSippImplantStlStructureQueryOptions = <TData = Awaited<ReturnType<typeof getSippImplantStlStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    structureId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSippImplantStlStructure>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSippImplantStlStructureQueryKey(taskId,structureId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSippImplantStlStructure>>> = ({ signal }) => getSippImplantStlStructure(taskId,structureId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId && structureId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSippImplantStlStructure>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSippImplantStlStructureQueryResult = NonNullable<Awaited<ReturnType<typeof getSippImplantStlStructure>>>
export type GetSippImplantStlStructureQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets an individual structure in the STL format
 */
export const useGetSippImplantStlStructure = <TData = Awaited<ReturnType<typeof getSippImplantStlStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    structureId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSippImplantStlStructure>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSippImplantStlStructureQueryOptions(taskId,structureId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets an individual structure in the STL format
 */
export const getSippSleeveStlStructure = (
    taskId: string,
    structureId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    
    return axios.get(
      `/sipp/${taskId}/sleeve/stl/${structureId}`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetSippSleeveStlStructureQueryKey = (taskId: string,
    structureId: string,) => {
    return [`/sipp/${taskId}/sleeve/stl/${structureId}`] as const;
    }

    
export const getGetSippSleeveStlStructureQueryOptions = <TData = Awaited<ReturnType<typeof getSippSleeveStlStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    structureId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSippSleeveStlStructure>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSippSleeveStlStructureQueryKey(taskId,structureId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSippSleeveStlStructure>>> = ({ signal }) => getSippSleeveStlStructure(taskId,structureId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId && structureId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSippSleeveStlStructure>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSippSleeveStlStructureQueryResult = NonNullable<Awaited<ReturnType<typeof getSippSleeveStlStructure>>>
export type GetSippSleeveStlStructureQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets an individual structure in the STL format
 */
export const useGetSippSleeveStlStructure = <TData = Awaited<ReturnType<typeof getSippSleeveStlStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    structureId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSippSleeveStlStructure>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSippSleeveStlStructureQueryOptions(taskId,structureId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



