import { createNotification } from "@promaton/frontend-common";
import { FileType, useObjects, ViewerObjectUtils } from "@promaton/scan-viewer";
import { ScanData } from "@promaton/scan-viewer/dist/types/ScanData";
import { invalidate } from "@react-three/fiber";
import { Data3DTexture } from "three";

export const flipDataTexture = (axis: "x" | "y", data: Data3DTexture) => {
  const { width, height } = data.image;
  const target = new Uint8Array(data.image.data.buffer);
  const original = new Uint8Array(new ArrayBuffer(data.image.data.byteLength));
  original.set(target);

  const frameSize = width * height;
  original.forEach((val: number, i) => {
    const frame = Math.floor(i / frameSize);
    const frameOffset = frame * frameSize;
    const frameIndex = i - frameOffset;
    const frameRow = Math.floor(frameIndex / width);
    const frameRowPosition = frameIndex - frameRow * width;
    const flippedRow = height - frameRow - 1;
    if (axis === "y") {
      const flippedIndex = flippedRow * width + frameRowPosition;
      const flippedPosition = flippedIndex + frameOffset;
      target[flippedPosition] = val;
    } else if (axis === "x") {
      const flippedRowPosition = width - frameRowPosition - 1;
      const flippedIndex = frameRow * width + flippedRowPosition;
      const flippedPosition = flippedIndex + frameOffset;
      target[flippedPosition] = val;
    }
  });

  data.needsUpdate = true;
  return data;
};

export const flipDicom = async (axis: "x" | "y") => {
  try {
    const objectState = useObjects.getState();
    const dicomObj = Object.entries(objectState.objects).find(
      ([_, obj]) => obj?.objectType === FileType.DICOM
    )?.[1];
    if (!dicomObj) throw new Error("No DICOM file found");
    const data = (await ViewerObjectUtils.preloadViewerObject(
      dicomObj
    )) as unknown as ScanData;

    if (!data) throw new Error("No data found");
    console.log(data);

    flipDataTexture(axis, data.texture);
    if (axis === "x") {
      objectState.scanMetadata &&
        objectState.setScanMetadata({
          ...objectState.scanMetadata,
          flippedX: !objectState.scanMetadata.flippedX,
        });
    }
    if (axis === "y") {
      objectState.scanMetadata &&
        objectState.setScanMetadata({
          ...objectState.scanMetadata,
          flippedY: !objectState.scanMetadata.flippedY,
        });
    }

    invalidate();
    createNotification({
      text: `Flipped DICOM along ${axis.toUpperCase()} axis`,
      color: "success",
    });
  } catch (error) {
    createNotification({
      text: `Failed to flip DICOM: ${(error as any as Error)?.message}`,
      color: "error",
    });
  }
};
