import {
  Divider,
  Paper,
  Stack,
  SwipeableDrawer,
  Tab,
  Tabs,
} from "@mui/material";
import { LayerList } from "@promaton/scan-viewer";
import { FC, memo, useEffect } from "react";

import { useIsMobile } from "../hooks/useIsMobile";
import { useIsPresentationMode } from "../hooks/useIsPresentationMode";
import { useIsPublicViewer } from "../hooks/useIsPublicViewer";
import { SidebarTab, useAppState } from "../stores/useAppState";
import { useCopilot } from "../stores/useCopilot";
import { OnboardingStepSelector } from "../stores/useOnboarding";
import { Copilot } from "./Copilot";
import { InfoTab } from "./InfoTab";
import { LayerVisibilityControls } from "./LayerVisibilityControls";

export const Sidebar: FC<{ open?: boolean }> = memo(({ open }) => {
  const setOpen = useAppState((s) => s.setSidebarVisible);
  const mobile = useIsMobile();
  const isPresentationMode = useIsPresentationMode();
  const activeTab = useAppState((s) => s.sidebarTab);
  const setTab = useAppState((s) => s.setSidebarTab);
  const isPublicViewer = useIsPublicViewer();

  useEffect(() => {
    return () => {
      useCopilot.getState().restart();
    };
  }, []);

  const content = (
    <Stack
      sx={{
        width: {
          xs: "100%",
          sm: 300,
          lg: 350,
          xl: 400,
        },
        height: "100%",
        position: "relative",
      }}
    >
      <Tabs
        value={activeTab}
        textColor="inherit"
        onChange={(_, v) => setTab(v)}
      >
        <Tab
          label={SidebarTab.LAYERS}
          value={SidebarTab.LAYERS}
          color="inherit"
        />
        <Tab label={SidebarTab.INFO} value={SidebarTab.INFO} color="inherit" />;
        {!isPublicViewer && (
          <Tab
            label={SidebarTab.COPILOT}
            value={SidebarTab.COPILOT}
            color="inherit"
          />
        )}
      </Tabs>
      <Divider />
      {activeTab === SidebarTab.LAYERS && (
        <Stack>
          <LayerVisibilityControls />
          <LayerList
            id={OnboardingStepSelector.SIDE_BAR}
            sx={{
              width: "100%",
              flex: 1,
            }}
          />
        </Stack>
      )}
      {activeTab === SidebarTab.INFO && <InfoTab />}
      {activeTab === SidebarTab.COPILOT && !isPublicViewer && <Copilot />}
    </Stack>
  );

  useEffect(() => {
    // Close initially
    if (open && mobile) setOpen(false);
  }, [mobile]);

  if (isPresentationMode) return null;

  if (mobile) {
    const iOS =
      typeof navigator !== "undefined" &&
      /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
      <SwipeableDrawer
        disableDiscovery={iOS}
        anchor="left"
        open={!!open}
        sx={{ zIndex: (t) => t.zIndex.modal }}
        onClose={() => setOpen(false)}
        onOpen={() => {
          setOpen(true);
        }}
        PaperProps={{
          variant: "outlined",
          sx: {
            width: "90vw",
            borderWidth: 0,
          },
        }}
      >
        {content}
      </SwipeableDrawer>
    );
  }

  return open ? (
    <Paper
      square
      sx={{
        backgroundImage: "none",
        borderWidth: 0,
        borderRightWidth: 1,
      }}
      variant="outlined"
    >
      {content}
    </Paper>
  ) : null;
});
